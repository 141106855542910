:root {
  --ff-primary: 'Source Sans Pro', sans-serif;
  --ff-secondary: 'Source Code Pro', monospace;
  --ff-kaushan: 'Kaushan Script', cursive;
  
  --fw-reg: 300;
  --fw-med: 600;
  --fw-bold: 900;
  
  --clr-light: #FBF0E3;
  --clr-dark: #222020;
  --clr-accent: #84A161;
  
  --fs-h1: 3rem;
  --fs-h2: 2.25rem;
  --fs-h3: 1.25rem; 
  --fs-body: 1rem;

  --bs: 0.25em 0.25em 0.75em rgba(0,0,0,.25),
        0.125em 0.125em 0.25em rgba(0,0,0,.25);
  --bs-light: 0.25em 0.25em 0.75em rgba(0,0,0,.25),
              0.125em 0.125em 0.25em rgba(0,0,0,.15);
  --bs-heavy: 0.5em 0.5em 1em rgba(0,0,0,.75),
              0.25em 0.25em 0.5em rgba(0,0,0,.50);
  --bd-heavy-left: 0.5em 0.5em 1em rgba(0,0,0,.75),
                   0.25em 0.25em 0.5em rgba(0,0,0,.50);
  --bs-inset: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset, 
              0px -4px 4px 0px rgba(0, 0, 0, 0.25) inset;   
  --bs-inner-light: inset 0px 4px 10px 0px rgba(255, 255, 255, 0.42);

  /* Nav bar button height and weight */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #222020;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
  color: var(--clr-dark);
}
a::visted {
  color: var(--clr-dark);
}

.page-content {
  text-align: center;
  max-width: 787px;
  height: 100vh;
  margin: auto;
  box-shadow: 12px 0 15px -4px rgba(0, 0, 0, 0.8), -12px 0 8px -4px rgba(0, 0, 0, 0.8);
  background-image: url('/public/images/wildlife-app-bg.png');
  background-position: center;
  background-size: cover;
}

.onboarding-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-image: url('/public/images/wildlife-app-bg.png');
  background-position: center;
  background-size: 150%;
}
.onboarding-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.onboarding-header > h1 {
  position: absolute;
  text-align: center;
  top: 100px;
  max-width: 200px;
  font-size: var(--fs-h2);
  background-color: black;
  color: transparent;
  text-shadow: 0px 2px 3px #8f775b;
  -webkit-background-clip: text;
  background-clip: text;
}

.onboarding-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 100%;
}

.btn {
  display: inline-block;
  padding: 1rem 2rem;
  width: 100%;
  margin: 0.5rem;
  border: none;
  border-radius: 50px;
  background-color: var(--clr-light);
  color: var(--clr-dark);
  font-weight: var(--fw-med);
  font-size: var(--fs-h3);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: var(--bs-heavy);
}
.btn:hover {
  background-color: var(--clr-accent);
  color: var(--clr-light);
  box-shadow: var(--bs-light);
  box-shadow: var(--bs-inset);
}

.bottom-nav-container {
  width: 100vw;
  max-width: 787px;
  position: fixed;
  bottom: 0;
  height: calc(100vw / 6);
  max-height: calc(787px / 6);
  display: flex;
  z-index: 100;
}

.bottom-nav__btn {
  width: calc(100vw / 6);
  max-width: calc(787px / 6);
  height: 100%;;
  background: var(--clr-light);
  color: var(--clr-dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  border-right: solid 1px rgba(255, 255, 255, 0.03);
  border-left: solid 1px rgba(0, 0, 0, 0.2);
  font-size: .75rem;
  gap: 0.25rem;
  
}
.bottom-nav__btn svg {
  width: 20px;
  height: 20px;
  fill: var(--clr-dark);
}

.bottom-nav__btn:hover {
  box-shadow: var(--bs-inset);
}

.leaflet-container {
  height: 100vh;
  width: 100%;
  max-width: 1000px;
  z-index: 1;
}

/* =========== */
/* UPLOAD PAGE */
/* =========== */

.upload-page-content {
  display: grid;
  height: calc(100vh - (100vw / 6));
  grid-template-rows: 70px 1fr;
  grid-template-areas: "header" "form";
  justify-content: center;
}

.header-upload {
  grid-area:  header;
  height: 70px;
}
.header-upload h1 {
  font-size: var(--fs-h2);
  color: var(--clr-dark);
  text-shadow: 0px 2px 3px #8f775b;
  -webkit-background-clip: text;
  background-clip: text;
  padding: 1em;
}

.form-upload {
  grid-area: form;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  margin: 3em 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 1em;
  box-shadow: var(--bs);
}

.form-upload input, 
.form-upload textarea {
  width: 100%;
  padding: 0.5em;
  margin: 0.5em;
  border: none;
  border-radius: 5px;
  box-shadow: var(--bs-light);
  font-size: var(--fs-body);
  background-color: var(--clr-light);
}

.form-upload label {
  font-size: var(--fs-h3);
  color: var(--clr-dark);
  text-shadow: 0px 2px 3px #8f775b;
  -webkit-background-clip: text;
  background-clip: text;
  text-align: left;
}

.form-upload input[type="submit"] {
  margin: 1em;
  border: none;
  border-radius: 5px;
  background-color: var(--clr-accent);
  color: var(--clr-light);
  font-size: var(--fs-h3);
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: var(--bs-heavy);
  height: 60px;
}
.form-upload .image-upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  width: 100%;
  margin-bottom: 1em;
}

.form-upload input[type="file"] {
  display: none;
}

.form-upload .upload-camera, 
.form-upload .upload-gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: var(--clr-light);
  width: 50%;
  aspect-ratio: 1;
  background: var(--clr-light);
  border-radius: 10px;
  box-shadow: var(--bs-light);
}

.form-upload .upload-camera svg,
.form-upload .upload-gallery svg {
  width: 40px;
  height: 40px;
  fill: var(--clr-dark);
}